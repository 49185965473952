<template>
    <div>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        <template v-slot:item="{ item }">
            <v-breadcrumbs-item
                @click.prevent="goBack()"
                href="#"
                :disabled="item.disabled"
            >
                {{ item.text }}
            </v-breadcrumbs-item>
            </template>
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
            <v-overlay
          :absolute="absolute"
          :value="overlay"
        >
            <v-progress-circular
        indeterminate
        color="primary"
        ></v-progress-circular>
        </v-overlay>
        <v-card-title>Role Description</v-card-title>
        <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm" lazy-validation>
        <v-container class="fill-height"
        fluid>
        <v-row>
                <v-col cols="12">
                    <v-text-field outlined dense label="Profile Type" :value="getType" class="col-6" readonly></v-text-field>
                    <v-text-field outlined dense label="Role" :value="getUser" class="col-6" readonly></v-text-field>
                </v-col>
        </v-row>
            <v-row v-if="!loading">
                <v-col md="12" v-for="(item, index, i) of perm_list_grouped" :key="i" v-if="perm_list_grouped[index][0].selected.length > 0">
                    <span>{{perm_list_grouped[index][0].parent_name}}</span>
                    <v-divider v-if="perm_list_grouped[index][0].selected.length > 0"></v-divider>
                    <v-row v-if="perm_list_grouped[index][0].selected.length > 0">
                        <v-col md="3" v-for="(it, idx) of perm_list_grouped[index]" :key="idx" v-if="it.selected.length > 0">
                            <v-card tile flat outlined>
                                <v-card-title class="text-body-1 bg-color-blue-main white--text" style="padding-top: 5px; padding-bottom: 5px">{{it.name}}</v-card-title>
                                <v-card-text>
                                    <v-checkbox v-for="(action, index2) of it.action" hide-details v-model="it.selected" :value="action.id" :key="index2" v-if="it.selected.includes(action.id)" disabled>
                                        <template v-slot:label class="checkbox-role">{{action.label}}</template>
                                    </v-checkbox>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
        </v-form>
        </v-card>
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            {{ swal.message }}
        </v-snackbar>
    </div>
</template>

<script>
import timer from '../../assets/js/sleeper.js';
import {ERR} from '../../assets/js/errhandle.js';
import {perm_list} from '@/assets/js/permcustomer.js';
import _ from 'lodash';

export default {
    name: 'Role Description',
    title: 'Role Description',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },
                {
                text: 'Business',
                disabled: true,
                href: '#',
                },
                {
                text: 'Car Park Operator',
                disabled: false,
                href: '#',
                },
                {
                text: 'Users',
                disabled: true,
                href: '#',
                },
                {
                text: 'Operator Profile Details',
                disabled: true,
                href: '#',
                },
                {
                text: 'Role Description',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            headers: [
                { text: '#', value: 'row', width: '5%' },
                { text: 'Role ID', value: 'RoleID', width: '5%' },
                { text: 'Name', value: 'RoleName', width: '25%' },
            ],
            tableloading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: '',
                scolor: '',
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,
            },
            form: [ 
                {type: [], rule: [], selected: '' },
            ],
            role_selected: [],
            perm_list: [],
            perm_list_grouped: [],
            select_all: false,
            btn_loading: false,
            access_record: [],
            overlay: false,
            absolute: true,
            loading: false
        }
    },

    async created(){
        //this.perm_list = await perm_list.list();
        await this.listPerms();
        let grouppedArray=_.groupBy(this.perm_list,'parent_id');
        console.log('grouppedArray', grouppedArray);
        this.perm_list_grouped = grouppedArray;
    },

    computed: {
      getUser(){
          let user = this.$store.getters.getUsersCustomer.find(item => {
              return item.ID == this.$route.params.role_id;
          })
          return user.RoleName;
      },

      getType(){
          let type = this.$store.getters.getTypesCustomer.find(item => {
              return item.ID == this.$route.params.type_id;
          })
          return type.TypeName;
      }
    },

    methods: {
        async listPerms(){
            this.loading = true;
            this.perm_list = await perm_list.list();
            let self = this;
            try{
                let response = await this.$store.dispatch('viewAccessCustomer', {});
                this.access_record = response.data.record;
                if (response.data.code === 'AP000'){
                    let dt = response.data.record;

                    for(let [index, item] of this.perm_list.entries()){
                        for(let [id, it] of item.action.entries()){

                            const urls = Array.from(new Set(dt.map(a => a.Path)));
                            const result = it.url.every(val => urls.includes(val));
                            
                            let cnt = 0;
                            let pass = false;
                            for (let i=0; i<it.url.length; i++){
                                let TypeRole = dt.map(item => item.TypeRole.slice(1,-1).split(','));
                                let Type = TypeRole.map(item => item.map(item2 => item2.split('#')[0]));
                                let Role = TypeRole.map(item => item.map(item2 => item2.split('#')[1]));
                                let newdt = dt.map(function(entry) {
                                    entry.type = entry.TypeRole.slice(1,-1).split(', ').map(i => i.split('#')[0]);
                                    entry.role = entry.TypeRole.slice(1,-1).split(', ').map(i => i.split('#')[1]);
                                    entry.newTypeRole = {type: [...entry.TypeRole.slice(1,-1).split(', ').map(i => i.split('#')[0])],
                                                         role: [...entry.TypeRole.slice(1,-1).split(', ').map(i => i.split('#')[1])]};
                                    return entry;
                                });

                               let getPath = newdt.filter(tr => 
                                                    tr.Path == it.url[i]
                                                   );
                                let getTR = getPath.some(function (tr, indexMain) {
                                                let idx = 0; let truth;
                                                for (let ij=0; ij<tr.newTypeRole.type.length; ij++){
                                                    if (Number(tr.newTypeRole.type[ij]) == Number(self.$route.params.type_id) && Number(tr.newTypeRole.role[ij]) == Number(self.$route.params.role_id))
                                                        truth = true;
                                                }

                                                return truth;
                                            });

                                if (getTR){
                                    cnt++;

                                    if (cnt == it.url.length){
                                        pass = true;
                                        //console.log(pass);
                                    }
                                }
                            }
                            if (pass){
                                this.perm_list[index].selected.push(it.id);
                            }
                        }
                    }
                }

                else
                {
                    this.swal.scolor = 'red';
                    this.swal.notification = true;
                    this.swal.message = ERR.HANDLE(response.data.code);
                }

                this.loading = false;
            }

            catch(err){
                console.log(err);
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Server error. Unable to fetch results";
            }
        },

        async updateAPIPermissions(arr){
            try{
                let response;
                let isSuccess = false;
                let code;
                let reqselected = [];
                let reqxselected = [];
                console.log('length', arr.length);
                for (var i=0; i<arr.length; i++){
                    for (var j=0; j<arr[i].length; j++){
                        reqxselected.push({path: arr[i][j].Path, typerolearray: arr[i][j].TypeRole});
                    }
                }

                console.log('reqxselected', reqxselected);
                response = await this.$store.dispatch("updateAccessCustomer", {selected: reqxselected}); //update api access path as well to tie with web path
                if (response.data.code === 'AP000') code = response.data.code; isSuccess = true;
            }

            catch(err){
                console.log(err);
                this.swal.scolor = 'red';
                this.swal.notification = true;
                this.swal.message = "Something went wrong. Please try again later";
                this.btn_loading = false;
            }
        },

        async updatePerms(){
            let dt = this.getMergedRoleItems();
            let allselections = [];
            console.log(dt);

            for(let [index, item] of this.perm_list.entries()){
                        let array_intersection = item.action.filter(i => item.selected.includes(i.id)); //get selected items;
                        var array_xintersection = item.action.filter(i => !item.selected.includes(i.id)); //get unselected items;

                        console.log('sel', array_intersection);

                        let selectedFinal = dt.filter(a => array_intersection.some(b => this.comparatorURL(a, b)));
                        let selectedxFinal = dt.filter(a => array_xintersection.some(b => this.comparatorURL(a, b)));

                        let selectedNotInApi = array_intersection.filter(a => selectedFinal.every(b => this.comparatorXURL(a, b)));

                        let selectnew = [];
                        for(let [index2, item2] of selectedFinal.entries()){
                            if (!item2.TypeRole.includes(`${this.$route.params.type_id}#${this.$route.params.role_id}`))
                            item2.TypeRole = this.insertBeforeLastOccurrence(item2.TypeRole, ']', `, ${this.$route.params.type_id}#${this.$route.params.role_id}`)

                            else{
                                let a = item2.TypeRole.replaceAll(`${this.$route.params.type_id}#${this.$route.params.role_id}`, "");
                                let b = a.replaceAll(/^,|,$|(,)+/g, '$1');
                                let c = b.replaceAll(' ,', '');
                                let d = c.replaceAll(/^,|,$|(,)+/g, '$1');
                                let e = d.replace(/,\s*]$/, "]"); //remove last comma
                                item2.TypeRole = this.insertBeforeLastOccurrence(e, ']', `, ${this.$route.params.type_id}#${this.$route.params.role_id}`);
                            }
                        }

                        for(let [index2, item2] of selectedxFinal.entries()){
                            let a = item2.TypeRole.replaceAll(`${this.$route.params.type_id}#${this.$route.params.role_id}`, "");
                                let b = a.replaceAll(/^,|,$|(,)+/g, '$1');
                                let c = b.replaceAll(' ,', '');
                                let d = c.replaceAll(/^,|,$|(,)+/g, '$1');
                                item2.TypeRole = d.replace(/,\s*]$/, "]"); //remove last comma
                        }

                        let arrforselectednotinapi = [];
                        for(let [index2, item2] of selectedNotInApi.entries()){
                            for(let [index3, item3] of item2.url.entries()){
                                item2.TypeRole = `[${this.$route.params.type_id}#${this.$route.params.role_id}]`;
                                item2.Path = item2.url;

                                arrforselectednotinapi.push({
                                    TypeRole: `[${this.$route.params.type_id}#${this.$route.params.role_id}]`,
                                    Path: item3
                                })
                            }
                        }

                        this.btn_loading = true;
                        this.overlay = true;

                        allselections.push(selectedFinal);
                        allselections.push(selectedxFinal);
                        allselections.push(arrforselectednotinapi);
                    }

                    let allselectionsfiltered = allselections.filter(item => item.length != 0);

                    console.log('allselections', allselectionsfiltered);

                    await this.updateAPIPermissions(allselectionsfiltered); //-to be uncommented
        },

        insertBeforeLastOccurrence(strToSearch, strToFind, strToInsert) {
            var n = strToSearch.lastIndexOf(strToFind);
            if (n < 0) return strToSearch;
            return strToSearch.substring(0,n) + strToInsert + strToSearch.substring(n);    
        },

        getMergedRoleItems(){
            //to prepare merged role id;
            let items = this.access_record;
            let selectedroleid = [];
            let sss; let sss2;
            selectedroleid.push(this.$route.params.role_id);
            selectedroleid = selectedroleid.map(Number);
            for (var i=0; i<items.length; i++){
                const types = [...items[i].TypeRole.slice(1,-1).split(', ').map(i => i.split('#')[0])];
                types = types.map(Number);
                sss2 = Array.from(new Set(types.concat(this.$route.params.type_id)));
                items[i].typeMerged = sss2.join();
                
                const roles = [...items[i].TypeRole.slice(1,-1).split(', ').map(i => i.split('#')[1])];
                roles = roles.map(Number);
                sss = Array.from(new Set(roles.concat(selectedroleid)));
                items[i].roleMerged = sss.join();
            }

            return items;
        },

        comparator(a, b) {
            let checkRole = b.Role.split(',').some(i => {
                return Number(i) === Number(this.$route.params.role_id);
            });

            let checkType = b.Type.split(',').some(i => {
                return Number(i) === Number(this.$route.params.type_id);
            });

            const urls = Array.from(new Set(this.access_record.map(a => a.Path)))
            const result = a.url.every(val => urls.includes(val));

            console.log('result', result);
            console.log('checkRole', checkRole);
            console.log('checkType', checkType);
            console.log('////////');
            return result && checkRole && checkType;
        },

        comparatorURL(a, b) {
            let it = b.url.some(item => item == a.Path);
            return it;
        },

        comparatorXURL(a, b) {
           let truth = false;
           let truth2 = [];
           for (let i=0; i<a.url.length; i++){
               if (a.url[i] !== b.Path){
                   truth = true;
               }
               truth2.push(truth);
           }

           let final = truth2.every(item => item == true);

           return final;
        },

        selectAll(){
            for(let [index, item] of this.perm_list.entries()){
                for(let [index2, item2] of item.action.entries()){
                    console.log(item2);
                    if (this.select_all === true){
                        let ifChecked = this.perm_list[index].selected.some(i => {
                            return i == item2.id;
                        })

                        if (!ifChecked)
                            this.perm_list[index].selected.push(item2.id);
                    }

                    else{
                        this.perm_list[index].selected = [];
                    }
                }
            }
        },

        async beforeCreateForm(){
            await this.updatePerms();
            this.swal.scolor = 'green';
            this.swal.notification = true;
            this.swal.message = "Access permission has been updated!";
            this.btn_loading = false;
            this.overlay = false;
            this.$router.push({path: '/home/spconsolesetting/usersetting/users'});
        },

        goBack(){
            this.$router.push({path: '/home/spconsolesetting/usersetting/users'});
        }
    }
}
</script>